<template lang="pug">
.om-onboarding-branching.mb-6.mb-md-0
  wizard-top.mb-4.mb-md-5(
    v-if="!isOnMobileOnboarding"
    :show-progress="false"
    :show-back="true"
    @backClick="navigateBack"
  )
    .container
      .row
        .col-3
          om-monks.pr-5(monk="medit-wizard")
        .col-9
          .row
            .col-5.pt-3
              .d-flex.flex-column
                wizard-title.mb-3 {{ $t('onboarding.wizard.choosePath.left.title') }}
                .color-gray-ultra-dark.mb-6 {{ $t('onboarding.wizard.choosePath.left.description') }}
                om-button.lets-do-it(primary large @click="redirectToWizard()") {{ $t('onboarding.wizard.choosePath.buttons.start') }}
            .col-auto
              wizard-separator {{ $t('or') }}
            .col-5.pt-3
              .right-column-content.d-flex.flex-column.justify-content-center
                wizard-title.mb-4 {{ $t('onboarding.wizard.choosePath.rightTop.title') }}
                .color-gray-ultra-dark.mb-4 {{ $t('onboarding.wizard.choosePath.rightTop.description') }}
                om-link.mb-2(primary withIconRight)
                  a.skip-wizard(@click="redirectToTemplates") {{ $t('chooseATemplate') }}
</template>

<script>
  import WizardSeparator from '@/components/Wizard/Separator.vue';
  import WizardTop from '@/components/Wizard/Top.vue';
  import { track } from '@/services/xray';
  import { mapState } from 'vuex';
  import { UilCheckCircle } from '@iconscout/vue-unicons';
  import sharedMixin from '@/views/Wizard/shared';
  import navigationMixin from '../accountSetup-navigation';

  export default {
    components: {
      WizardSeparator,
      WizardTop,
      UilCheckCircle,
    },
    mixins: [navigationMixin, sharedMixin],
    computed: {
      ...mapState(['account', 'locale']),
      isOnMobileOnboarding() {
        return this.$route.path.includes('mobile-onboarding');
      },
    },
    methods: {
      async redirectToTemplates() {
        track('accountSetup-branching-selector');
        await this.$store.dispatch('finishOnboarding');
        await this.updateWizardPreferences({ skip: true });
        this.$router.push({ name: 'templates', params: { recLoader: false } });
      },
      redirectToWizard() {
        track('accountSetup-branching-wizard');
        this.$router.push({
          name: 'onboarding_wizard',
          params: { step: 'goals' },
        });
      },
      navigateBack() {
        this.$router.go(-1);
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .om-wizard-separator-line
    height: 7rem
  .title
    margin-bottom: 64px
    text-align: center
    .small
      font-size: 18px
  .om-onboarding-branching
    .container
      margin-top: 5rem
    .lets-do-it
      max-width: max-content
      padding: 0.625rem 2.125rem
    .right-column-content
      height: 100%
      padding-left: 2rem
</style>
